import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { PageInfoBar } from "@components/common/page-info-bar";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { TeamTabs } from "@router/route-params";
import { useAppParams } from "@router/router-helper";
import { InviteMemberToTeam } from "@pages/members/teams/invite-member-to-team";
import { EditableTeamName } from "@pages/members/teams/team-details/editable-team-name";
import { useAppSelector } from "@store/store-helper";
import { selectedTeamWithRoleSelector } from "@store/teams/teams-selector";
import Team from "@assets/icons/new/team.svg?react";
import { sphereColors } from "@styles/common-colors";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { Stack } from "@mui/material";
import { formatUserRoleType } from "@utils/data-display";

interface Props {
  /** Flag whether the content should be shown as skeletons because it is still loading */
  isLoading?: boolean;
}

/**
 * Info bar for the team overview tabs
 */
export function TeamDetailsInfoBar({ isLoading = false }: Props): JSX.Element {
  const { teamTabs } = useAppParams();
  const selectedTeamId = useAppSelector(selectedTeamWithRoleSelector);
  const { canEditTeams } = useHasUserValidRoleCompanyLevel();

  if (!selectedTeamId || isLoading) {
    // Early exit with a loading skeleton if the team is not yet loaded
    return <PageInfoBar isLoading={true} />;
  }

  return (
    <PageInfoBar
      items={[
        {
          columnSize: 3,
          content: (
            <Stack flexDirection={"row"} gap={"12px"} alignItems={"center"}>
              <SphereAvatar
                icon={<Team style={{ height: "26px", width: "26px" }} />}
                size="medium"
                backgroundColor={sphereColors.gray700}
                shouldHideWhiteRim={true}
              />
              <EditableTeamName
                team={selectedTeamId}
                isEditable={canEditTeams}
              />
            </Stack>
          ),
        },
        {
          columnSize: 3,
          content: (
            <FaroTextField
              label="Workspace role"
              initialValue={formatUserRoleType(selectedTeamId.role)}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
      ]}
      action={
        /**
         * The `isManaged` attribute determines if the team can be removed.
         * So far, I've only seen this enabled for the "All Members" team.
         * For this reason, I'm removing the "Invite Members" button based on this attribute.
         */
        teamTabs === TeamTabs.members && !selectedTeamId.isManaged ? (
          <InviteMemberToTeam team={selectedTeamId} />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to keep the position of items constant
          <></>
        )
      }
    />
  );
}
